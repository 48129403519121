.comments__wrap {
  position: fixed;
  z-index: 1000000000;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(66, 66, 66, 0.6);
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  overflow: hidden;

  @media screen and (max-width: 550px) {
    top: unset;
    height: 70vh;
    border-top-right-radius: 24px;
    border-top-left-radius: 24px;
  }

  .comments__comments {
    width: 100%;
    max-width: 100%;
  }
}

.comments__comments {
  width: 100%;
  max-width: 600px;
  background: rgba(29, 29, 29, 0.9);
  backdrop-filter: blur(20px);
  display: flex;
  flex-direction: column;
  height: 100%; /* Added this line */
  gap: 42px;
}

.comments__header {
  padding: 42px 42px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}

.comments__title {
  display: flex;
  gap: 6px;

  > :last-child {
    font-family: "PlayfairDisplay";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    color: $green-500;
  }
}

.comments__close {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border: 1px solid $secondary-500;
  border-radius: 100%;

  > svg {
    width: 24px;
    height: 24px;
    color: $white;
  }
}

.comments__body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto; /* Added this line */
}

.comments__list {
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding: 0 42px;
}

.comments__list-item {
  display: flex;
  flex-direction: column;
  gap: 6px;

  > :first-child {
    color: $white;
  }

  > :last-child {
    display: flex;
    align-items: center;

    > :first-child {
      color: $green-500;
      font-family: "PlayfairDisplay";
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: 24px;

      > span {
        font-family: "Montserrat";
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;
        color: $white;
      }
    }
  }
}

.comments__cta {
  display: flex;
  justify-content: center;
  padding: 18px 0 42px;
  position: relative;
  background: $primary-500;

  > button {
    width: calc(100% - 42px - 42px);
  }
}

.comments__form-wrap {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 18px 42px 18px;
  background: $primary-500;
  transform: translateY(100%);
  transition: transform 1500ms;
  z-index: 1;
}

.comments__form-wrap--open {
  transform: translateY(0);
  transition: transform 1500ms, z-index 1500ms 1350ms;
}

.comments__form {
  > div {
    display: flex;
    gap: 16px;
  }
}
