html,
body,
#root,
.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  background-color: $primary-500;
}

*,
*::after,
*::before {
  font-family: $font-family;
}

.main {
  display: flex;
  padding: 0 30px;
  flex-direction: column;
  flex: 1 0 0;
  align-self: stretch;
  gap: 30px;
  background-color: $primary-500;
  max-width: 1900px;
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;

  @media screen and (min-width: 480px) {
    padding: 0 60px;
  }

  @media screen and (min-width: 1040px) {
    padding: 0 120px;
  }
}

// THIS IS A HACK BECAUSE OF LAYOUTING ISSUE
.main--home {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  align-self: stretch;
  gap: 30px;
  background-color: $primary-500;
  width: 100%;
  min-height: 100vh;

  > section {
    width: 100%;
    max-width: 1900px;
    margin: 0 auto;
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  @media screen and (min-width: 480px) {
    > section {
      padding-left: 60px !important;
      padding-right: 60px !important;
    }
  }

  @media screen and (min-width: 1040px) {
    > section {
      padding-left: 120px !important;
      padding-right: 120px !important;
    }
  }

  > .home__background {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

input {
  font-size: 12px;
  line-height: 1.5;
  color: $white;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%;

  .player-wrapper__icon {
    background-color: $secondary-500;
    border-radius: 100%;
    padding: 30px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      width: 24px;
      height: 24px;
      color: $primary-500;
    }
  }
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

img {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.not-found {
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
