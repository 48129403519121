.new-serial {
  background: linear-gradient(135deg, #59d9c6 0%, #215aae 100%);
  padding: 30px;
  margin: 0 -30px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .link.link--stretch {
    gap: 24px;
    align-items: center;
  }

  .splide__arrows {
    position: absolute;
    top: -24px;
    right: 0;
    display: flex;
    gap: 18px;
    transform: translateY(-100%);

    .splide__arrow {
      position: relative;
      width: 30px;
      height: 30px;
      background-color: transparent;
      border: 1px solid $white;
      opacity: 1;
      top: unset;
      left: unset;
      right: unset;
      bottom: unset;
      transform: unset !important;

      svg {
        fill: $white;
        width: 12px;
        height: 12px;
      }
    }
  }

  @media screen and (min-width: 480px) {
    padding: 60px;
    margin: 0 -60px;
    gap: 30px;

    .new-serial_information {
      max-width: 420px;
    }

    .link.link--stretch {
      gap: 24px;
      align-items: center;
      justify-content: flex-start;
    }

    .splide__arrows {
      top: -30px;

      .splide__arrow {
        width: 42px;
        height: 42px;
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
  }

  @media screen and (min-width: 1040px) {
    margin: 0 -60px;
    padding: 60px;
    border-radius: 36px;

    .splide__arrows {
      .splide__arrow {
        width: 48px;
        height: 48px;

        svg {
          width: 18px;
          height: 18px;
        }
      }
    }

    .new-serial_slide {
      display: flex;
      gap: 42px;

      > * {
        flex: 1;
      }
    }

    .new-serial_information {
      max-width: unset;
      display: flex;
      flex-direction: column;
      align-self: stretch;
      padding-top: 0;
      padding-bottom: 25px;

      > :last-child {
        margin-top: auto;
      }
    }

    > h2 {
      grid-column: 1 / 3;
    }
  }
}

.new-serial_carousel {
  .carousel {
    margin: 0px !important;
  }
}

.new-serial_preview-wrap {
  display: flex;
  flex-direction: column;
}

.new-serial_preview {
  width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: 14px;
  overflow: hidden;

  > img {
    width: 100%;
    height: 100%;
  }
}

.new-serial_information {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  align-self: start;

  > :nth-child(2) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: 5em;
  }
}

.new-serial_rating {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 6px;
}

.new-serial_title {
  margin-bottom: 6px;
}

.new-serial_educator {
  &.paragraph {
    color: $white;
    font-weight: 500;
  }

  > span {
    font-weight: 300;
  }
}

.new-serial_preview-bar {
  border-radius: 6px;
  background: $white;
  margin: 24px 0;
  height: 6px;
}

.new-serial__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
