.success {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px 0;

  @media screen and (min-width: 480px) {
    gap: 36px;
    padding: 60px 0;

    .steps {
      justify-content: flex-start;
    }

    .success_page {
      gap: 30px;

      > svg {
        align-self: center;
        margin-top: 18px;
        margin-bottom: 12px;
        max-width: 400px;
      }
    }

    .success_content {
      gap: 18px;
    }

    .success_text {
      gap: 18px;
    }

    .success_link {
      margin-top: 48px;
    }
  }

  @media screen and (min-width: 1200px) {
    gap: 76px;

    .success_page {
      flex-direction: row-reverse;
      align-items: center;
      gap: 60px;

      > svg {
        align-self: center;
        margin-top: 0;
        margin-bottom: 0;
        padding: 90px;
        max-width: 600px;
      }

      > div,
      > svg {
        flex: 1;
      }
    }
  }
}

.success_page {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.success_content {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.success_text {
  display: flex;
  flex-direction: column;
  gap: 12px;

  > :last-child {
    color: $green-500;
    font-weight: 500;
  }
}

.success_link {
  display: flex;
  align-items: center;

  > h3 {
    opacity: 0.4;
  }
}
