.episode-slides {
  position: relative;
  z-index: 1;

  @media screen and (min-width: 1040px) {
    .episode-slides_background {
      img {
        width: 360px;
        height: 360px;
      }
    }
  }
}

.episode-slides_background {
  position: absolute;
  top: 0;
  right: -30px;
  z-index: -1;
  opacity: 0.2;

  img {
    width: 180px;
    height: 180px;
    filter: brightness(0) saturate(100%) invert(81%) sepia(62%) saturate(372%)
      hue-rotate(121deg) brightness(82%) contrast(90%);
  }
}

.episode-slides_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
  flex-wrap: wrap;
}

.episode-slides_title {
  display: flex;
  align-items: center;
  gap: 12px;
}

.episode-slides_description {
  margin-bottom: 24px;
}

.episode-slides_slide {
  cursor: pointer;
  border-radius: 12px;
  overflow: hidden;
}
