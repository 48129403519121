.u-gap-12 {
  gap: 12px;
}

.u-gap-18 {
  gap: 18px;
}

.u-gap-24 {
  gap: 24px;
}

.u-gap-54 {
  gap: 54px;
}

.u-gap-60 {
  gap: 60px;
}

.u-flex {
  display: flex;
}

.u-flex-column {
  display: flex;
  flex-direction: column;
}

.u-align-items-center {
  align-items: center;
}

.u-justify-content-center {
  justify-content: center;
}

.u-justify-content-space-between {
  justify-content: space-between;
}

.u-text-align-center {
  text-align: center;
}

.u-mt-12 {
  margin-top: 12px;
}

.u-mt-24 {
  margin-top: 24px;
}

.u-mb-6 {
  margin-bottom: 6px;
}

.u-mb-12 {
  margin-bottom: 12px;
}

.u-mb-18 {
  margin-bottom: 18px;
}

.u-mb-24 {
  margin-bottom: 24px;
}

.u-mb-30 {
  margin-bottom: 30px;
}

.u-mb-36 {
  margin-bottom: 36px;
}

.u-mb-100 {
  margin-bottom: 100px;
}

.u-color-secondary-500 {
  color: $secondary-500;
}

.u-color-gradient-blue {
  background: linear-gradient(135deg, #59d9c6 0%, #598cd9 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.u-pt-88 {
  padding-top: 88px;
}

.u-pb-88 {
  padding-bottom: 88px;
}

.u-pt-30 {
  padding-top: 30px;
}

.u-pb-30 {
  padding-bottom: 30px;
}

.u-p-30 {
  padding: 30px;
}

.u-mx-30-negative {
  margin: 0 -30px;
}

.u-background-linear-blue {
  background: linear-gradient(135deg, #59d9c6 0%, #215aae 100%);
}

.u-color-white {
  color: $white;
}

.u-font-weight-500 {
  font-weight: 500;
}

.u-aspect-ratio-3-4 {
  aspect-ratio: 3/4;
}

.u-aspect-ratio-16-9 {
  aspect-ratio: 16/9;
}

.u-playfair-display {
  font-family: "PlayfairDisplay";
}

.u-green-500 {
  color: #59d9c6;
}

.u-opacity-1 {
  opacity: 1 !important;
}

.u-border-red {
  border: 1px solid red;
}

.u-width-full {
  width: 100%;
}

.u-margin-negative {
  margin-left: -30px;
}

.u-width-vw {
  width: 100vw;
}

.u-relative {
  position: relative;
}

.u-fixed-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.u-blur-background {
  background: linear-gradient(180deg, rgba(29, 29, 29, 0) 0%, #1d1d1d 100%),
    linear-gradient(180deg, rgba(29, 29, 29, 0) 0%, #1d1d1d 100%);
  backdrop-filter: blur(20px);
}

.u-pb-24 {
  padding-bottom: 24px;
}

.u-px-24 {
  padding-left: 24px;
  padding-right: 24px;
}

.u-pt-12 {
  padding-top: 12px;
}

.u-svg-color-green {
  > svg {
    color: #59d9c6 !important;
  }
}
