.banner {
  background-color: $secondary-500;
  padding: 12px 6px 12px 24px;

  padding-left: 30px !important;
  padding-right: 30px !important;


  @media (display-mode: standalone), (display-mode: minimal-ui) {
    display: none;
  }

  > div {
    width: 100%;
    max-width: 1900px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 6px;
  }

  p {
    color: $primary-500;
  }

  svg {
    width: 12px;
    height: 12px;
  }

  @media screen and (min-width: 480px) {
      padding-left: 60px !important;
      padding-right: 60px !important;
  }

  @media screen and (min-width: 1040px) {
      padding-left: 120px !important;
      padding-right: 120px !important;
  }
}

.banner__cta {
  color: $primary-500;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 18px;
    height: 18px;
  }
}

.banner__wrap {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 20000000;
  background: rgba(29, 29, 29, 0.01);
  backdrop-filter: blur(20px);
}

.banner__modal {
  background: var(--Black-300, #2F2F2F);
  max-height: 90vh;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: 30px 30px 0px 0px;
}

.banner__modal-header {
  padding: 18px 18px 18px 30px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  width: 100%;

  > button {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid $secondary-500;
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      width: 18px;
      height: 18px;
      color: $white;
    }
  }
}

.banner__modal-content {
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.banner__modal-body {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  align-items: center;
  justify-content: center;
  padding-top: 0px;

  > img {
    max-width: 300px;
  }
}

.banner__modal-footer {
  padding: 0px 30px 54px;
  display: flex;
  justify-content: flex-end;
}
