.content-category {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;

  @media screen and (min-width: 600px) {
    .content-category_grid {
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 18px;
    }

    .content-category_image {
      > img,
      & {
        width: 360px;
        height: 360px;
      }
    }
  }

  @media screen and (min-width: 1100px) {
    .content-category_grid {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 18px;
    }
  }

  @media screen and (min-width: 1500px) {
    .content-category_grid {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-gap: 18px;
    }
  }
}

.content-category_header {
  margin-bottom: 6px;
  display: flex;
  align-items: flex-start;
  gap: 12px;
}

.content-category_more {
  margin-left: auto;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  gap: 12px;

  span {
    color: $white;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }

  svg {
    width: 14px;
    height: 14px;
    color: $secondary-500;
  }
}

.content-category_description {
  margin-bottom: 24px;
}

.content-category_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;
  position: relative;
}

.content-category_item {
  position: relative;
  cursor: pointer;

  > img {
    aspect-ratio: 3 / 4;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 18px;
    filter: brightness(0.75);
  }
}

.content-category_image {
  position: absolute;
  top: -18px;
  right: -18px;
  opacity: 0.2;

  > img,
  & {
    width: 180px;
    height: 180px;
  }
}

.content-category_item-category {
  position: absolute;
  top: 12px;
  right: 12px;
  display: flex;
  align-items: center;
  gap: 6px;

  > .badge {
    backdrop-filter: blur(10px);
  }
}

.content-category_item-episodes {
  position: absolute;
  border-radius: 6px;
  background: rgba(29, 29, 29, 0.1);
  backdrop-filter: blur(10px);
  padding: 3px;
  bottom: 12px;
  right: 12px;
}

.content-category__loader {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-category_pagination {
  margin-top: 32px;
  display: flex;
  justify-content: center;
}
