.icon-button {
  display: flex;
  align-items: center;
  cursor: pointer;

  > .link {
    padding: 0;
    margin-right: auto;
  }

  > svg {
    width: 18px;
    height: 18px;
    color: $secondary-500;
  }
}
