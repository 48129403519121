.register {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px 0;

  @media screen and (min-width: 800px) {
    padding: 60px 0;
    gap: 36px;

    .steps {
      justify-content: flex-start;
    }

    .register_header {
      display: flex;
      gap: 30px;
      margin-bottom: 60px;
      align-items: center;
      justify-content: space-between;

      > :first-child {
        max-width: 444px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 18px;
      }

      > :last-child {
        max-width: 400px;
        width: 100%;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    display: flex;
    align-items: unset;
    gap: 24px;

    > :not(:first-child) {
      display: flex;
      gap: 60px;
      align-items: flex-start;

      > div,
      > svg {
        width: 100%;
        max-width: 600px;
        min-width: 400px;
      }

      > svg {
        padding: 60px;
      }
    }

    .register_header {
      > :first-child {
        max-width: 100%;
      }
    }

    .register_cta {
      margin-top: 48px;
    }

    .register_link {
      margin-top: 60px;
    }
  }
}

.register_header {
  margin-bottom: 30px;

  > .register_header-content {
    display: flex;
    flex-direction: column;
    gap: 18px;
  }
}

.register_form {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.register_cta {
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  text-align: center;

  > h3 {
    opacity: 0.4;
  }
}

.register_social {
  display: flex;
  align-items: center;
  gap: 12px;
}

.register_link {
  display: flex;
  align-items: center;
  margin-top: 30px;

  > h3 {
    opacity: 0.4;
  }
}
