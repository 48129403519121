.pagination {
  display: flex;
  align-items: center;
  gap: 6px;

  > .pagination_button,
  .pagination_prev,
  .pagination_next {
    width: 30px;
    height: 30px;
    background-color: transparent;
    border: 1px solid #fff;
    opacity: 1;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      width: 18px;
      height: 18px;
      color: white;
    }
  }

  .pagination_button {
    color: $white;
    background-color: #2f2f2f;
    border-color: #2f2f2f;

    &:disabled {
      border-color: #424242;
      background-color: #424242;
    }
  }

  .pagination_prev,
  .pagination_next {
    &:disabled {
      opacity: 0.5;
    }
  }
}
