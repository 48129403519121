.forgot-password {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px 0;

  @media screen and (min-width: 800px) {
    gap: 36px;
    padding: 60px 0;

    .steps {
      justify-content: flex-start;
    }

    .forgot-password_content {
      gap: 60px;
    }

    .forgot-password_header {
      display: flex;
      gap: 30px;

      > div {
        flex: 2;
      }

      svg {
        flex: 1;
        max-width: 400px;
      }
    }

    .forgot-password_form > button {
      margin-top: 60px;
    }
  }

  @media screen and (min-width: 1200px) {
    .forgot-password_page {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 60px;
      align-items: flex-start;

      > svg {
        max-width: 600px;
        padding: 90px;
      }
    }
  }
}

.forgot-password_page {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.forgot-password_content {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.forgot-password_header-content {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.forgot-password_form {
  > button {
    margin-top: 30px;
  }
}

.forgot-password_link {
  display: flex;
  align-items: center;

  > h3 {
    opacity: 0.4;
  }
}
