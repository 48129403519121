/* SCSS Reset */

/* Box sizing */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove margins and paddings */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
  padding: 0;
}

/* Remove list styles */
ul,
ol {
  list-style: none;
}

/* Typography */
html {
  font-size: 12px;
  font-family: $font-family;
  line-height: 1.33;
}

/* Remove default hyperlink styling */
a {
  text-decoration: none;
  color: inherit;
}

/* Remove default form field styles */
input,
textarea,
button,
select {
  appearance: none;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  outline: none;
  font-family: inherit;
  cursor: pointer;
}

input,
textarea,
button,
select {
  &[type="text"],
  &[type="password"],
  &[type="email"],
  &[type="url"],
  &[type="number"],
  &[type="date"],
  &[type="datetime-local"],
  &[type="month"],
  &[type="week"],
  &[type="time"],
  &[type="tel"],
  &[type="search"],
  &[type="color"],
  &[type="range"],
  &[type="file"],
  &[type="checkbox"],
  &[type="radio"],
  &[multiple],
  &[readonly],
  &[type="checkbox"],
  &[type="radio"] {
    appearance: none;
    border: none;
    background: transparent;
    outline: none;
  }

  &:focus {
    outline: none;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: $white;
  -webkit-box-shadow: 0 0 0px 1000px $primary-500 inset;
  transition: background-color 5000s ease-in-out 0s;
}

input::placeholder,
textarea::placeholder,
select::placeholder {
  color: $white;
  opacity: 0.2;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  display: none;
}

/* Remove outline on focused elements */
:focus {
  outline: none;
}
