.categories {
  padding: 30px 0;

  .category {
    padding: 0 30px;
  }

  @media screen and (min-width: 480px) {
    .categories_slider {
      margin: 0 -60px;
      padding: 0 60px;
    }

    .category {
      padding: 0 60px;
    }

    .categories_slide_header {
      margin-bottom: 36px;
    }
  }

  @media screen and (min-width: 1040px) {
    .carousel {
      margin: 0;
    }

    .category {
      padding: 0;
    }

    .category_image {
      padding: 0;
    }

    .category_content {
      padding: 0;
    }
  }

  @media screen and (max-width: 1200px) {
    .categories_slide_header {
      flex-direction: column;
      align-items: flex-start;

      > .slider__arrows {
        width: 100%;
        justify-content: space-between;
      }
    }
  }
}

.categories_cta {
  margin-top: auto;
  padding-top: 24px;
}

.categories_slider {
  margin: 0 -30px;
  padding: 0 30px;
}

.categories_slide_header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  gap: 12px;
  justify-content: space-between;
}
