.home_grid {
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  grid-gap: 60px;

  @media screen and (min-width: 480px) {
    gap: 78px;
    padding: 78px 0;
  }

  @media screen and (min-width: 1040px) {
    gap: 120px;
    padding: 120px 0;
  }
}

.select_plan_paragraph {
  text-wrap: nowrap;
}

// THIS IS A HACK BECAUSE OF LAYOUTING ISSUE
.home__background {
  background-image: url("../../assets/images/background.jpg");
  background-size: cover;
  background-position: center;
  max-width: 100% !important;

  > div {
    width: 100%;
    max-width: 1900px;
    margin: 0 auto;
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  @media screen and (min-width: 480px) {
    > div {
      padding-left: 60px !important;
      padding-right: 60px !important;
    }
  }

  @media screen and (min-width: 1040px) {
    > div {
      padding-left: 120px !important;
      padding-right: 120px !important;
    }
  }
}
