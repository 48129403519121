.subscription {
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  gap: 42px;

  @media screen and (min-width: 480px) {
    padding: 60px 0;
  }
}

.subscription_header {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
}

.subscription_carousel {
  .carousel {
    margin: 0 -16px;
  }

  .splide__slide {
    width: 100%;
  }
}

.subscription_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;

  @media screen and (min-width: 1450px) {
    grid-template-columns: 1fr 1fr;

    > .subscription-plan {
      align-self: center;
    }
  }
}
