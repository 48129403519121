.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background: #1d1d1d;
  z-index: 99999999;
  display: flex;
  flex-direction: column;
  transition: transform 0.5;

  &.sidebar--open {
    transform: translateX(0);
    transition: transform 0.5s;
  }

  &.sidebar--closed {
    transform: translateX(100%);
    transition: transform 0.5s;
  }

  @media screen and (min-width: 480px) {
    .sidebar_header {
      padding: 18px 36px 18px 48px;
    }

    .sidebar_body {
      padding: 12px 48px 0px 42px;
    }

    .sidebar_cta {
      padding: 12px 0 30px;
    }

    .search {
      padding: 12px;

      .search_icon {
        &,
        & > svg {
          width: 18px;
          height: 18px;
        }
      }

      .search_button {
        &,
        & > svg {
          width: 18px;
          height: 18px;
        }
      }

      .search_field {
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 18px;
      }
    }
  }
}

.sidebar_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 12px 12px 24px;
  width: 100%;
}

.sidebar_logo {
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    width: 110px;
    height: 55px;
  }
}

.sidebar_trigger {
  width: 48px;
  height: 48px;
  border: 1px solid $secondary-500;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 24px;
    height: 24px;
    color: $white;
  }
}

.sidebar_body {
  display: flex;
  flex-direction: column;
  padding: 12px 18px;
  height: calc(100vh - 72px);
}

.sidebar_nav {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  padding-left: 12px;

  > h3 {
    margin-bottom: 18px;
    color: rgba(255, 255, 255, 0.4);
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin-bottom: 36px;

    li {
      display: flex;
      align-items: center;
      gap: 12px;

      > p {
        color: $white;
        cursor: pointer;
      }

      svg {
        width: 18px;
        height: 18px;
        color: $white;
      }
    }
  }
}

.sidebar_cta {
  padding-bottom: 18px;
  padding-top: 30px;
  display: flex;
  gap: 12px;
}

.sidebar_form {
  margin-bottom: 24px;
}

.sidebar_user {
  margin-bottom: 24px;
}
