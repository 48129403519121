.badge {
  display: flex;
  align-items: center;
  width: fit-content;
  border-radius: 6px;
  cursor: pointer;

  > span {
    font-style: normal;
  }

  &.badge--outlined {
    border: 1px solid $white;

    > .paragraph {
      color: $white;
    }
  }

  &.badge--sm {
    padding: 4px 6px;
    display: flex;
    align-items: center;
    gap: 3px;

    > img {
      width: 14px;
      height: 14px;
      filter: invert(100%) sepia(85%) saturate(2%) hue-rotate(179deg)
        brightness(112%) contrast(100%);
    }
  }

  &.badge--md {
    padding: 4px 12px;
    display: flex;
    align-items: center;
    gap: 3px;

    > img {
      width: 14px;
      height: 14px;
      filter: invert(100%) sepia(85%) saturate(2%) hue-rotate(179deg)
        brightness(112%) contrast(100%);
    }
  }
}
