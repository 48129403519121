.auth-banner {
  @media screen and (min-width: 480px) {
    .auth-banner_banner {
      padding: 178px 0 186px;

      > h1,
      > .paragraph {
        max-width: 420px;
      }

      & > h1 {
        margin-bottom: 24px;
      }

      & > .paragraph {
        margin-bottom: 42px;
      }
    }

    .auth-banner_banner-cta {
      gap: 24px;
    }
  }

  @media screen and (min-width: 1040px) {
    .auth-banner_banner {
      padding: 158px 0;

      > h1,
      > .paragraph {
        max-width: 540px;
      }

      > h1 {
        margin-bottom: 30px;
      }

      > .paragraph {
        margin-bottom: 54px;
      }

      .auth-banner_banner-cta {
        gap: 30px;
      }
    }

    .auth-banner_categories {
      padding: 120px 0;
    }
  }

  &.auth-banner--non-auth {
    .auth-banner_banner {
      padding: 52px 0;

      h1 {
        margin-bottom: 18px;
      }

      > .paragraph {
        color: $green-500;
      }

      .auth-banner_search {
        margin: 30px 0px;
      }

      .auth-banner_grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 12px;

        > :first-child {
          grid-column: 1 / 3;
        }
      }
    }

    @media screen and (min-width: 480px) {
      .auth-banner_banner {
        padding: 136px 0;

        h1 {
          margin-bottom: 24px;
        }

        > .paragraph {
          color: $green-500;
        }

        .auth-banner_search {
          margin: 42px 0px;
        }

        .auth-banner_grid {
          display: grid;
          grid-template-columns: repeat(6, 1fr);
          grid-gap: 18px;

          > :nth-child(1),
          > :nth-child(2) {
            grid-column: auto / span 3;
          }

          > :nth-child(3),
          > :nth-child(4),
          > :nth-child(5) {
            grid-column: auto / span 2;
          }
        }
      }
    }

    @media screen and (min-width: 1200px) {
      .auth-banner_banner {
        padding: 180 0;

        h1 {
          margin-bottom: 30px;
        }

        .auth-banner_search {
          margin: 54px 0px;
        }

        .auth-banner_grid {
          grid-template-columns: repeat(5, 1fr);

          > :nth-child(1),
          > :nth-child(2),
          > :nth-child(3),
          > :nth-child(4),
          > :nth-child(5) {
            grid-column: unset;
          }
        }
      }
    }
  }
}

.auth-banner_banner {
  padding: 56px 0;

  > h1,
  > .paragraph {
    max-width: 270px;
  }

  > h1 {
    margin-bottom: 18px;
  }

  > .paragraph {
    margin-bottom: 30px;
  }
}

.auth-banner_banner-cta {
  display: flex;
  flex-direction: column;
  gap: 18px;

  > .paragraph--p1 {
    background: linear-gradient(135deg, #59d9c6 0%, #598cd9 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.auth-banner_no-account {
  display: flex;
  align-items: center;
}

.auth-banner_grid {
  > div {
    cursor: pointer;
  }
}
