.header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 12px 12px 24px;
  background-color: rgba(29, 29, 29, 0.9);
  z-index: 10000000;
  gap: 30px;

  @media screen and (min-width: 480px) {
    padding: 18px 36px 18px 48px;
  }

  &.header--desktop {
    width: 100%;
    padding: 0;

    .header_left {
      display: flex;
      align-items: center;
      gap: 48px;
    }

    .header_nav {
      display: flex;
      align-items: center;
    }

    .header_nav-list {
      display: flex;
      gap: 24px;
      align-items: center;
    }

    .header_link {
      position: relative;
      cursor: pointer;
      min-height: 42px;
      display: flex;
      align-items: center;

      &::marker {
        display: none;
      }

      &.header_link--active {
        > p {
          text-decoration: underline;
          text-decoration-color: $secondary-500;
        }
      }

      > p {
        padding: 6px 12px;
        color: $white;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
    }

    .header_container {
      max-width: 1900px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin: 0 auto;
      padding: 30px 96px 18px;
    }

    .header_dropdown {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      width: max-content;
      padding: 18px 30px;
      gap: 18px;
      border-radius: 12px;
      border: 1px solid $primary-100;
      background: rgba(29, 29, 29, 0.99);
      margin-top: 26px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      > li {
        padding: 18px 12px;
        color: $white;
        font-family: "Montserrat";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        text-align: left;

        > span {
          display: flex;
          align-items: center;
          gap: 12px;
          width: max-content;

          svg {
            width: 18px;
            height: 18px;
            flex-shrink: 0;
          }
          img {
            width: 18px;
            height: 18px;
            flex-shrink: 0;
            filter: invert(95%) sepia(41%) saturate(4252%) hue-rotate(147deg)
              brightness(90%) contrast(86%);
          }
        }
      }
    }

    .user_initials {
      margin-right: 0;
      width: 42px;
      height: 42px;
      flex-shrink: 0;

      span {
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
      }
    }
  }
}

.header_logo {
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    width: 110px;
    height: 55px;
  }
}

.header_trigger {
  width: 48px;
  height: 48px;
  border: 1px solid transparent;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 24px;
    height: 24px;
    color: $white;
  }
}

.header_sidebar {
  display: flex;
  align-items: center;
  gap: 30px;

  .user_initials {
    margin-right: 0;
    width: 36px;
    height: 36px;

    span {
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
    }
  }
}

.header-sidebar_cta {
  display: flex;
  align-items: center;
  gap: 30px;

  > svg {
    width: 24px;
    height: 24px;
    color: $white;
    cursor: pointer;
  }

  > .header-sidebar_cta-buttons {
    display: flex;
    align-items: center;
    gap: 12px;

    > button {
      width: max-content;
      padding: 12px 30px;
    }
  }
}

.install__pwa {
  color: $white;
  width: 28px;
  height: 28px;

  @media (display-mode: standalone), (display-mode: minimal-ui) {
    display: none;
  }

  &.install__pwa--hidden {
    display: none;
  }
}
