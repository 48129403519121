.legal {
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  gap: 90px;

  @media screen and (min-width: 480px) {
    padding: 60px 0px;
  }

  @media screen and (min-width: 1040px) {
    padding: 120px 0px;
  }

  h1 {
    margin-bottom: 12px;
  }

  h2 {
    margin-bottom: 12px;
  }

  > div {
    > div {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }
  }

  ol {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
