.empty {
  background-color: $primary-100;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  @media screen and (min-width: 480px) {
    .empty--horizontal {
      padding: 12px 6px;
    }

    .empty--vertical {
      > :last-child {
        padding: 12px 6px;
      }
    }
  }
}

.empty--horizontal {
  padding: 24px;
  gap: 21px;
  max-width: 700px;
  width: 100%;

  > svg {
    max-width: 400px;
    width: 100%;
  }

  > :first-child {
    text-align: center;
    color: $white;
  }
}

.empty--vertical {
  max-width: 274px;
  width: 100%;
  flex-direction: column;

  > :last-child {
    padding: 24px 6px;
    text-align: center;
    color: $white;
  }

  > svg {
    width: 100%;
  }
}
