.my-profile {
  padding: 30px 0;

  @media screen and (min-width: 560px) {
    .my-profile_header {
      display: flex;
      gap: 30px;

      .my-profile_header-content {
        width: 100%;
      }

      > svg {
        max-width: 240px;
        width: 100%;
      }
    }

    .my-profile_profile-cta {
      flex-direction: row;
    }

    .my-profile_page {
      gap: 60px;
    }

    .my-profile_header {
      align-items: center;
    }

    .my-profile_header-content {
      gap: 30px;
    }

    .my-profile_content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 24px;
    }

    .my-profile_cta {
      display: grid;
      grid-template-columns: 1fr 1fr;

      > :first-child {
        grid-column: 1 / 3;
      }
    }
  }

  @media screen and (min-width: 1450px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 60px;

    > div,
    > svg {
      flex-grow: 1;
      gap: 60px;
    }

    > svg {
      padding: 0 45px;
    }

    .my-profile_content {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
}

.my-profile_page {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.my-profile_header-content {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.my-profile_profile-cta {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.my-profile_content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.my-profile_information {
  display: grid;
  grid-gap: 4px;

  > p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.my-profile_cta {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.my-profile_link {
  display: flex;
  align-items: center;
  margin-top: 30px;

  > h3 {
    opacity: 0.4;
  }
}
