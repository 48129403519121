.confirm-plan {
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  align-items: center;

  p {
    color: $white;
  }

  @media screen and (min-width: 480px) {
    .confirm-plan__image {
      display: flex;
    }
  }

  @media screen and (min-width: 1500px) {
    flex-direction: row-reverse;
    justify-content: space-between;
    gap: 60px;

    .confirm-plan__image {
      max-width: 600px;
    }

    .confirm-plan__content {
      max-width: 600px;
      width: 100%;
    }

    .confirm-plan__cta {
      flex-direction: row;

      > :first-child {
        width: max-content;
        flex-shrink: 0;
      }
    }
  }
}

.confirm-plan__image {
  display: none;
  max-width: 480px;
  width: 100%;
  margin-bottom: 30px;
}

.confirm-plan__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.confirm-plan__title {
  margin-bottom: 60px;
}

.confirm-plan__card {
  padding: 30px;
  border-radius: 30px;
  border: 1px solid var(--Blue-Gradient, #59d9c6);
  background: linear-gradient(
    135deg,
    rgba(89, 217, 198, 0) 0%,
    rgba(89, 140, 217, 0.4) 100%
  );
  display: flex;
  flex-direction: column;
  gap: 24px;

  > :last-child {
    color: $green-500;
  }
}

.confirm-plan__cta {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: 60px;
}

.confirm-plan__discount {
  border-radius: 6px;
  background: var(
    --Yellow-Gradient,
    linear-gradient(135deg, #c6a924 0%, #f2eb91 100%)
  );
  display: flex;
  padding: 3px 6px;
  align-items: center;
  margin: 0px 6px;

  > p {
    color: $primary-500;
  }
}

.confirm-plan__card-title {
  display: flex;
  align-items: center;
}
