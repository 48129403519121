.watch-episode {
  position: sticky;
  margin: 0 -30px;
  top: 79px;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, #1d1d1d 0%, rgba(29, 29, 29, 0) 100%),
    linear-gradient(180deg, #1d1d1d 0%, rgba(29, 29, 29, 0) 100%);
  backdrop-filter: blur(20px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  padding: 12px 24px;

  > button {
    width: max-content;
    padding: 9px 18px;
  }
}

.watch-episode_title {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
}
