.serial {
  display: flex;
  flex-direction: column;
  padding: 0 0 30px;
  min-height: 100vh;

  @media screen and (min-width: 480px) {
    .serial_cover,
    .serial-header {
      margin: 0 -60px 24px;
    }

    .serial_cta {
      > :first-child {
        display: flex;
        flex-direction: row;

        > :first-child {
          width: max-content;
        }
      }
    }
  }

  @media screen and (min-width: 1040px) {
    .serial_cover {
      margin: 0 -120px 30px;
    }
  }

  @media screen and (min-width: 1550px) {
    padding: 24px 0;

    .serial_content {
      > :first-child {
        display: flex;
        gap: 60px;

        > :first-child {
          width: 60%;
          flex-shrink: 0;
        }

        > :last-child {
          width: 40%;
        }
      }
    }

    .serial-header {
      position: fixed;
      left: 0;
      right: 0;
      top: 103px;
      margin: 0;
      padding-right: 96px;
      padding-left: 96px;
    }

    .serial_cover {
      margin: 0;
      width: 100%;
      margin-bottom: 42px;
      border-radius: 18px;
    }

    .serial_info {
      gap: 24px;
      margin-bottom: 42px;

      > h2 {
        margin-bottom: 18px;
      }

      > .paragraph.paragraph--p2 {
        margin-bottom: 18px;
      }
    }

    .serial_rest {
      > h3 {
        margin-bottom: 12px;
      }

      > ul {
        gap: 30px;
      }
    }
  }
}

.serial_content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.serial-header {
  position: sticky;
  top: 91px;
  left: 0;
  right: 0;
  width: 100vw;
  margin: 0 -30px;
  background: rgba(29, 29, 29, 0.9);
  backdrop-filter: blur(20px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  z-index: 100;

  > p {
    overflow: hidden;
    color: $white;
    text-overflow: ellipsis;
    text-transform: uppercase;
  }

  > button {
    width: min-content;
    padding: 9px 12px;
  }
}

.serial_cover {
  width: 100vw;
  margin: 0 -30px 24px;
  aspect-ratio: 16 / 9;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  > .course-price {
    position: absolute;
    top: 8px;
    right: 8px;
    width: auto !important;
    height: auto !important;
    aspect-ratio: 1/1;
  }

  > div,
  iframe {
    width: 100% !important;
    height: 100% !important;
  }
}

.serial_rating {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 6px;
}

.serial_info {
  margin-bottom: 30px;

  > h2 {
    margin-bottom: 6px;
  }

  > p {
    margin-bottom: 6px;
  }

  > .serial_educator {
    font-weight: 300;

    &,
    span {
      line-height: 16px;
    }

    span {
      color: $white;
      font-weight: 500;
    }
  }
}

.serial_cta {
  display: flex;
  flex-direction: column;
  gap: 12px;

  > :first-child {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  > :last-child {
    display: flex;
    gap: 12px;
  }
}

.serial_rest {
  > h3 {
    margin-bottom: 24px;
    color: #59d9c6;
  }

  > ul {
    display: flex;
    flex-direction: column;
    gap: 18px;
  }
}

.serial_footer {
  display: flex;
  gap: 24px;
  padding: 24px;
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: linear-gradient(180deg, rgba(29, 29, 29, 0) 0%, #1d1d1d 100%),
    linear-gradient(180deg, rgba(29, 29, 29, 0) 0%, #1d1d1d 100%);
  backdrop-filter: blur(20px);

  > button {
    padding: 12px 24px;
  }
}
