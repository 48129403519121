$secondary-color: $secondary-500;

.link {
  display: flex;
  align-items: center;
  padding: 6px 12px;
  cursor: pointer;

  &.link--primary,
  &.link--tertiary {
    .link_label {
      font-size: 14px;
      line-height: 18px;
      border-bottom: 1px solid $secondary-color;

      &:active,
      &:hover {
        color: $secondary-color;
      }
    }

    svg {
      color: $secondary-color;
    }
  }

  &.link--tertiary {
    .link_label {
      border-bottom: 1px solid $red;

      &:active,
      &:hover {
        color: $red;
      }
    }
  }

  &.link--secondary {
    .link_label {
      font-size: 12px;
      line-height: 18px;
    }

    .link_icon {
      width: 14px;
      height: 14px;

      svg {
        width: 14px;
        height: 14px;
        color: $secondary-color;
      }
    }

    &:active,
    &:hover {
      .link_label {
        color: $secondary-color;
      }
    }
  }

  &.link--stretch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0;
  }

  .link_label {
    color: $white;
    font-weight: 500;
  }

  .link_icon {
    width: 18px;
    height: 18px;
    margin-left: 6px;

    svg {
      width: 18px;
      height: 18px;
    }
  }

  @media screen and (min-width: 480px) {
    &.link--primary {
      .link_label {
        font-size: 16px;
        line-height: 24px;
      }
    }

    &.link--secondary {
      .link_label {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}
