.login {
  padding: 30px 0;

  @media screen and (min-width: 800px) {
    padding: 72px 0;

    .login_header {
      flex-direction: row;
      gap: 30px;
      margin-bottom: 60px;
      align-items: center;
      justify-content: space-between;

      > :first-child {
        max-width: 444px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 18px;
      }

      > :last-child {
        max-width: 400px;
        width: 100%;
      }
    }

    .login_remember-me {
      margin-bottom: 48px;
    }

    .login_link {
      margin-top: 60px;
    }
  }

  @media screen and (min-width: 1200px) {
    display: flex;
    gap: 60px;
    padding: 60px 0;
    align-items: unset;

    > div {
      width: 100%;
      max-width: 600px;
      min-width: 400px;
    }

    > svg {
      padding: 90px;
      max-width: 600px;
    }

    .login_header {
      > :first-child {
        max-width: 100%;
      }
    }
  }
}

.login_header {
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-bottom: 42px;
}

.login_form {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.login_remember-me {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.login_cta {
  display: flex;
  flex-direction: column;
  gap: 18px;
  text-align: center;

  > h3 {
    opacity: 0.4;
  }
}

.login_social {
  display: flex;
  gap: 12px;
}

.login_link {
  display: flex;
  align-items: center;
  margin-top: 30px;

  > h3 {
    opacity: 0.4;
  }
}
