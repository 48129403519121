.steps {
  display: flex;
  gap: 6px;
  justify-content: center;
}

.steps_step {
  width: 30px;
  height: 6px;
  border-radius: 6px;
  background: $primary-300;

  &.steps_step--active {
    background: $white;
    opacity: 0.7;
  }
}
