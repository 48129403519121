.select-plan {
  padding-bottom: 60px;

  @media screen and (min-width: 834px) {
    .select-plan_card {
      flex-direction: row-reverse;
      justify-content: space-between;
      gap: 42px;
      align-items: center;
      padding: 30px;

      > svg {
        padding: 0;
        margin-bottom: 0;
        max-width: 400px;
      }

      .select-plan_content {
        .button {
          width: max-content !important;
        }
      }
    }
  }

  @media screen and (min-width: 1040px) {
    padding-bottom: 120px;
  }
}

.select-plan_card {
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 30px;
  border: 1px solid $secondary-900;
  background: linear-gradient(
    135deg,
    rgba(89, 217, 198, 0) 0%,
    rgba(89, 140, 217, 0.4) 100%
  );

  > svg {
    padding: 0 45px;
    margin-bottom: 24px;
    max-width: 400px;
    align-self: center;
  }
}

.select-plan_content {
  > h1 {
    margin-bottom: 12px;
  }

  > div {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    margin-bottom: 36px;

    > div {
      display: flex;
      flex-direction: column;
      flex: 1;

      > :first-child {
        color: $white;
      }

      > div {
        display: flex;
        align-items: flex-end;
        gap: 6px;

        > :first-child {
          color: $green-500;
        }

        > :last-child {
          padding-bottom: 6px;
          color: $white;
        }
      }
    }
  }
}
