$primary-color: $primary-100;
$secondary-color: $secondary-500;

.radio {
  padding: 6px 12px;

  &.radio--checked {
    .radio_indicator {
      background-color: $secondary-color;
      border-color: $secondary-color;

      > div {
        background-color: $primary-500;
      }
    }

    .radio_label {
      opacity: 1;
      color: $secondary-color;
    }
  }
}

.radio_inner {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.radio_field {
  display: none;
  visibility: hidden;
  width: 0;
  height: 0;
}

.radio_indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  border: 2px solid $primary-color;
  background-color: $primary-color;

  > div {
    width: 9px;
    height: 9px;
    border-radius: 100%;
    background-color: $primary-color;
    margin: 0;
    padding: 0;
  }
}

.radio_label {
  margin-left: 6px;
  color: $white;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  opacity: 0.4;
}
