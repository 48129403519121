.rating {
  display: flex;
  align-items: center;

  > svg {
    width: 18px;
    height: 18px;
    color: $secondary-500;
    margin-right: 6px;
  }

  > span {
    color: $white;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    font-family: "PlayfairDisplay";
  }
}
