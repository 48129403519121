$primary-color: $primary-500;
$secondary-color: $secondary-500;
$hover-color: $secondary-900;

.button {
  width: 100%;
  display: flex;
  padding: 16px 22px;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  border-radius: 12px;
  cursor: pointer;
  background-color: $secondary-color;
  border: 1px solid $secondary-color;

  &:active,
  &:hover {
    background-color: $hover-color;
    border-color: $hover-color;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background-color: $secondary-color;
    border-color: $secondary-color;
  }

  &.button--max-content {
    width: max-content;
  }

  &.button--secondary {
    border: 1px solid $secondary-color;
    background-color: transparent;

    .button_label {
      color: $secondary-color;
      font-weight: 500;
    }

    .button_loader,
    .button_icon svg,
    .button_loader {
      color: $secondary-color;
    }

    &:active,
    &:hover {
      border-color: $hover-color;

      .button_label,
      .button_loader,
      .button_icon svg {
        color: $hover-color;
      }
    }
  }

  &.button--white-outlined {
    border: 1px solid $white;
    background-color: transparent;

    .button_label {
      color: $white;
      font-weight: 500;
    }

    .button_loader,
    .button_icon svg {
      color: $white;
    }

    &:active,
    &:hover {
      opacity: 0.5;
    }
  }

  &.button--tertiary {
    border: 1px solid $primary-300;
    background-color: $primary-300;

    .button_label {
      color: $white;
    }

    .button_loader,
    .button_icon svg {
      color: $white;
    }

    &:active,
    &:hover {
      opacity: 0.8;
    }

    &:disabled {
      background-color: $primary-300;
      opacity: 1;

      .button_label {
        opacity: 0.4;
      }

      .button_icon svg {
        opacity: 0.4;
      }
    }
  }

  &.button--blue {
    border: 1px solid $green-500;
    background-color: $green-500;

    .button_label {
      color: $primary-color;
    }

    .button_loader,
    .button_icon svg {
      color: $primary-color;
    }

    &:active,
    &:hover {
      opacity: 0.8;
    }
  }

  &.button--error {
    background: $red-700;
    border-color: $red-700;

    .button_label,
    .button_loader {
      color: $white;
      font-weight: 500;
    }

    &:hover {
      background: $red;
      border-color: $red;
    }
  }

  @media screen and (min-width: 480px) {
    padding: 18px 24px;

    .button_label {
      font-size: 16px;
      line-height: 24px;
    }
  }

  @media screen and (min-width: 1040px) {
    padding: 18px 24px;
  }
}

.button_loader,
.button_icon {
  width: 18px;
  height: 18px;

  svg {
    height: 18px;
    width: 18px;
    color: $primary-color;
  }
}

.button_label {
  color: $primary-color;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  margin: 0 6px;
}

.button_inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 0;
}
