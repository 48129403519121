@font-face {
  font-family: "Montserrat";
  src: url("../../../public/fonts/Montserrat-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../../../public/fonts/Montserrat-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../../../public/fonts/Montserrat-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../../../public/fonts/Montserrat-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../../../public/fonts/Montserrat-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../../../public/fonts/Montserrat-ExtraBold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../../../public/fonts/Montserrat-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PlayfairDisplay";
  src: url("../../../public/fonts/PlayfairDisplay-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PlayfairDisplay";
  src: url("../../../public/fonts/PlayfairDisplay-SemiBold.woff2")
    format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PlayfairDisplay";
  src: url("../../../public/fonts/PlayfairDisplay-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

*,
*::after,
*::before {
  font-variant-numeric: lining-nums;
}

p {
  font-size: 12px;
  line-height: 1.33;
  color: $white-transparent;
  font-weight: 300;
}

h1 {
  font-family: "Montserrat";
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px;
  color: $white;

  @media screen and (min-width: 480px) {
    font-size: 42px;
    line-height: 54px;
  }

  @media screen and (min-width: 1040px) {
    font-size: 54px;
    line-height: 66px;
  }
}

h2 {
  color: $white;
  font-family: "Montserrat";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;

  @media screen and (min-width: 480px) {
    font-size: 30px;
    line-height: 42px;
  }

  @media screen and (min-width: 1040px) {
    font-size: 36px;
    line-height: 48px;
  }
}

h3 {
  &,
  > span {
    color: $white-transparent;
    font-family: "PlayfairDisplay";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  @media screen and (min-width: 480px) {
    &,
    > span {
      font-size: 18px;
      line-height: 24px;
    }
  }

  @media screen and (min-width: 1040px) {
    &,
    > span {
      font-size: 20px;
      line-height: 24px;
    }
  }
}

a {
  cursor: pointer;
}
