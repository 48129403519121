.card {
  display: flex;
  padding: 12px 12px 6px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #59d9c6;
  min-height: 75px;

  &:hover {
    opacity: 0.75;
  }

  > img {
    width: 24px;
    height: 24px;
    filter: invert(90%) sepia(84%) saturate(527%) hue-rotate(336deg)
      brightness(90%) contrast(91%);
  }

  > .paragraph.paragraph--p2 {
    padding: 6px 12px;
    color: $white;
    font-weight: 500;
  }

  @media screen and (min-width: 480px) {
    gap: 12px;
    padding: 24px 12px;

    > img {
      width: 42px;
      height: 42px;
    }
  }

  @media screen and (min-width: 1040px) {
    gap: 24px;
    padding: 30px 12px;

    > img {
      width: 48px;
      height: 48px;
    }
  }
}
