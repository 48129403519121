$primary-color: $primary-100;
$secondary-color: $secondary-500;
$red-color: $red;

.checkbox {
  display: flex;
  align-items: center;
  padding: 6px 12px;

  &.checkbox--error,
  &.checkbox--error.checkbox--checked {
    .checkbox_indicator {
      background-color: rgba($red-color, 0.2);

      svg {
        color: $red-color;
        visibility: visible;
      }
    }

    .checkbox_label {
      color: $white;
    }
  }

  &.checkbox--error.checkbox--error {
    .checkbox_indicator {
      svg {
        color: $red-color;
        visibility: hidden;
      }
    }

    .checkbox_label {
      color: $red-color;
    }
  }

  &.checkbox--checked {
    .checkbox_indicator {
      background-color: $secondary-color;

      svg {
        color: $primary-color;
        visibility: visible;
      }
    }

    .checkbox_label {
      color: $white;
    }
  }

  @media screen and (min-width: 480px) {
    .checkbox_label {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }

    .checkbox_indicator {
      margin-right: 9px;
    }
  }
}

.checkbox_inner {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox_label,
.checkbox_link {
  color: rgba(255, 255, 255, 0.4);
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.checkbox_link {
  border-bottom: 1px solid $secondary-color;
  color: $white;

  font-size: inherit;
  line-height: inherit;
}

.checkbox_field {
  display: none;
  visibility: hidden;
  width: 0;
  height: 0;
}

.checkbox_indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 6px;
  background-color: $primary-color;
  margin-right: 6px;

  svg {
    width: 12px;
    height: 12px;
    color: $primary-color;
    visibility: hidden;
  }
}
