.create-profile {
  display: flex;
  flex-direction: column;
  gap: 30px;
  flex-grow: 1;
  padding: 30px 0;

  @media screen and (min-width: 800px) {
    padding: 60px 0;
    gap: 36px;

    .steps {
      justify-content: flex-start;
    }

    .create-profile_header {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 30px;
      margin-bottom: 60px;

      > :first-child,
      > svg {
        flex: 1;
      }
    }

    .create-profile_form-place {
      width: 100%;
      flex-direction: row;
      gap: 24px;

      > .input {
        flex: 1;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .create-profile_page {
      display: flex;
      flex-direction: row;
      gap: 60px;
      align-items: flex-start;

      > div,
      > svg {
        flex: 1;
      }

      > svg {
        padding: 90px;
        max-width: 600px;
      }
    }
  }
}

.create-profile_header {
  margin-bottom: 30px;
}

.create-profile_header-content {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.create-profile_form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 12px;

  > button {
    margin-top: auto;
  }
}

.create-profile_form-place {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 12px;
}

.create-profile_cta {
  display: flex;
  gap: 12px;
}
