.search {
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 12px;
  background-color: $primary-300;
  gap: 12px;

  &.search--searchable {
    .search_button {
      visibility: visible;
    }
  }

  @media screen and (min-width: 480px) {
    padding: 18px;

    .search_icon {
      &,
      & > svg {
        width: 24px;
        height: 24px;
      }
    }

    .search_button {
      &,
      & > svg {
        width: 24px;
        height: 24px;
      }
    }

    .search_field {
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 18px;
    }
  }
}

.search_icon {
  width: 18px;
  height: 18px;
  flex-shrink: 0;

  svg {
    width: 18px;
    height: 18px;
    color: $white;
    opacity: 0.4;
  }
}

.search_field {
  flex-grow: 1;
}

.search_button {
  flex-shrink: 0;
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;

  svg {
    width: 18px;
    height: 18px;
    color: $secondary-500;
  }
}
