.course-price {
  border-radius: 100%;
  background-color: #dbc958;
  display: flex;
  align-items: center;
  padding: 16px;
  justify-content: center;

  > * {
    color: $primary-500;
  }
}
