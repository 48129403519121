.preview {
  display: flex;
  gap: 12px;
  cursor: pointer;

  &.preview--vertical {
    flex-direction: column;
    gap: 12px;

    .preview_image {
      width: 100%;
    }

    .preview_content {
      padding: 0 12px;
    }
  }
}

.preview_image {
  width: 37.5%;
  aspect-ratio: 16/9;
  flex-shrink: 0;
  border-radius: 12px;
  overflow: hidden;
  position: relative;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    filter: blur(10px) brightness(0.4);
  }
}

.preview_content {
  display: flex;
  flex-direction: column;
  gap: 6px;
  flex-shrink: 1;
}

.preview_title {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 18px;
  width: 100%;

  > p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.preview_description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.preview__ep {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 18px;
  margin-top: 18px;
  border: 1px solid $white;
  border-radius: 6px;
  padding: 5px 12px;

  > .paragraph {
    color: $white !important;
    font-weight: 500 !important;
  }
}

.preview_play {
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  margin: 12px;
  border-radius: 100%;
  border: 1px solid var(--White, #fff);
  background: var(
    --Black-Fade-Down,
    linear-gradient(180deg, rgba(29, 29, 29, 0) 0%, #1d1d1d 100%),
    linear-gradient(180deg, rgba(29, 29, 29, 0) 0%, #1d1d1d 100%)
  );

  &.preview_play--playing {
    background: $white;

    > svg {
      color: $primary-color;
    }
  }

  > svg {
    width: 18px;
    height: 18px;
    color: $white;
  }
}
