.content {
  display: flex;
  flex-direction: column;
  gap: 54px;
  padding: 54px 0;

  @media screen and (min-width: 480px) {
    gap: 72px;
    padding: 54px 0;

    .content_filters {
      > button {
        padding: 12px 18px !important;
        width: auto !important;

        .button_label {
          font-size: 14px !important;
          line-height: 18px !important;
        }
      }
    }
  }

  @media screen and (min-width: 1040px) {
    gap: 60px;
    padding: 60px 0;

    .content_form {
      gap: 18px;
    }

    .content_header {
      margin-bottom: 60px;
    }
  }
}

.content_header {
  display: flex;
  gap: 6px;
  margin-bottom: 48px;

  > :last-child {
    color: $green-500;
  }
}

.content_form {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.content_filters {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  > button {
    padding: 6px 12px !important;
    width: auto !important;

    .button_label {
      font-size: 14px !important;
      line-height: 18px !important;
    }
  }
}

.content-type {
  display: flex;
  flex-direction: column;
  padding: 54px 0;
  flex-grow: 1;

  .content_form {
    margin-bottom: 30px;
  }

  @media screen and (min-width: 480px) {
    padding: 54px 0;

    .content_form {
      margin-bottom: 60px;
    }

    .content-type_description {
      margin-bottom: 48px;
      max-width: 660px;
    }
  }

  @media screen and (min-width: 1040px) {
    padding: 60px 0;

    .content-type_description {
      margin-bottom: 60px;
    }
  }
}

.content-type_header {
  display: flex;
  gap: 6px;
  margin-bottom: 12px;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    width: 30px;
    height: 30px;
    filter: invert(98%) sepia(75%) saturate(6958%) hue-rotate(149deg)
      brightness(87%) contrast(94%);
  }

  h1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > :last-child {
    color: $green-500;
  }
}

.content-type_description {
  margin-bottom: 30px;
}
