.category {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  width: 100%;

  @media screen and (min-width: 480px) {
    gap: 36px;

    .category_title {
      margin-bottom: 36px;
    }

    .category_image {
      max-width: 600px;
    }
  }

  @media screen and (min-width: 1040px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 60px;

    .category_image {
      grid-row: 1 / 3;
    }

    > :nth-child(2) {
      padding: 30px 0;
    }

    .category_image {
      max-width: 400px;
      justify-self: center;
    }
  }
}

.category_image {
  width: 100%;
  padding: 0 48px;

  > svg {
    width: 100%;
    aspect-ratio: 1.25;
  }
}

.category_title {
  align-self: flex-start;
  color: $green-500;
}

.category_content {
  align-self: flex-start;

  > :first-child {
    color: $white;
    margin-bottom: 12px;
  }

  > :last-child {
    color: $white;
  }

  span {
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
    color: #59d9c6;
  }
}
