.c-educator {
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  gap: 60px;

  @media screen and (max-width: 600px) {
    .c-educator__header {
      flex-direction: column;

      > img {
        width: 50%;
        min-width: 240px;
        min-height: 240px;
        align-self: center;
        margin-top: 24px;
      }
    }
    .c-educator__more {
      flex-direction: column;
      align-items: flex-start;

      > ul {
        flex-wrap: wrap;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .c-educator__back {
      > button {
        height: 30px;
        width: 30px;

        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
}

.c-educator__header {
  display: flex;
  justify-content: space-between;

  > img {
    width: 240px;
    aspect-ratio: 1 / 1;
    align-self: center;
    margin-top: 24px;
    border-radius: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.c-educator__cta {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.c-educator__back {
  display: flex;
  align-items: center;

  > button {
    width: 42px;
    height: 42px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: $primary-300;

    > svg {
      width: 24px;
      height: 24px;
      color: $white;
    }
  }
}

.c-educator__information {
  margin-top: auto;

  > h1 {
    margin-bottom: 12px;
  }

  > p {
    color: $green-500;
  }
}

.c-educator__biography {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.c-educator__more {
  display: flex;
  gap: 12px;
  align-items: center;

  > ul {
    display: flex;
  }
}
