.episode {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 0 0 30px;

  @media screen and (min-width: 480px) {
    .episode_cover {
      margin: 0 -60px 30px;
    }

    .episode_cta {
      grid-template-columns: 1fr 1fr;

      > :first-child {
        grid-column: 1 / 3;
      }
    }
  }

  @media screen and (min-width: 1040px) {
    .episode_cover {
      margin: 0 -120px 30px;
    }
  }

  @media screen and (min-width: 1550px) {
    flex-direction: row;
    gap: 60px;
    padding: 24px 0;

    > :first-child {
      width: 100%;
    }

    > :last-child {
      max-width: 550px;
      width: 100%;
    }

    .episode_cover {
      margin: 0;
      width: 100%;
      margin-bottom: 46px;
      border-radius: 18px;
    }

    .episode_serial {
      svg {
        width: 24px;
        height: 24px;
      }
    }

    .episode_rest {
      margin-bottom: 100px;

      > h3 {
        margin-bottom: 6px;
      }

      > .paragraph--p2 {
        margin-bottom: 30px;
      }

      > ul {
        display: flex;
        flex-direction: column;
        gap: 30px;
      }
    }

    .episode_title {
      margin-bottom: 18px;
    }

    .episode_cta {
      margin-top: 42px;
    }

    .episode_cta {
      gap: 18px;
    }
  }
}

.episode_cover {
  width: 100vw;
  margin: 0 -30px;
  aspect-ratio: 16 / 9;
  margin-bottom: 24px;

  overflow: hidden;

  > div {
    width: 100% !important;
    height: 100% !important;
  }
}

.episode_serial {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  svg {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    color: $white;
    margin-right: 6px;
  }

  p {
    color: $white;
  }

  span {
    font-weight: 300;
  }
}

.episode_title {
  margin-bottom: 6px;
}

.episode_cta {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
  margin-top: 30px;
}

.episode_rest {
  > .paragraph--p1 {
    color: #59d9c6;
    margin-bottom: 6px;
  }

  > .paragraph--p2 {
    margin-bottom: 24px;
  }

  .splide__slide {
    width: 56vw;
  }
}

.episode_carousel {
  .carousel {
    margin: 0 !important;
  }
}
