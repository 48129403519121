.formats {
  display: flex;
  flex-direction: column;
  gap: 18px;

  @media screen and (min-width: 480px) {
    gap: 24px;

    .formats-card-grid {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 18px;

      > :nth-child(1),
      > :nth-child(2) {
        grid-column: auto / span 3;
      }

      > :nth-child(3),
      > :nth-child(4),
      > :nth-child(5) {
        grid-column: auto / span 2;
      }
    }

    .formats-search {
      margin: 12px 0;
    }
  }

  @media screen and (min-width: 1200px) {
    .formats-card-grid {
      grid-template-columns: repeat(5, 1fr);

      > :nth-child(1),
      > :nth-child(2),
      > :nth-child(3),
      > :nth-child(4),
      > :nth-child(5) {
        grid-column: unset;
      }
    }

    .formats-search {
      margin: 42px 0;
    }
  }
}

.formats-description {
  span {
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;
    color: #59d9c6;
  }
}

.formats-search {
  margin: 6px 0;
}

.formats-card-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;

  > div {
    cursor: pointer;
  }

  > :first-child {
    grid-column: 1 / 3;
  }
}
