.user {
  display: flex;
  align-items: center;
  width: 100%;
}

.user_initials {
  width: 42px;
  height: 42px;
  border-radius: 100%;
  background-color: rgba(219, 201, 88, 0.2);
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  span {
    color: $secondary-500;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    user-select: none;
  }
}

.user_information {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > :first-child {
    color: $white !important;
  }

  > :last-child {
    color: $secondary-500;
  }
}

.user_settings {
  margin-left: auto;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 18px;
    height: 18px;
    color: $white;
    opacity: 0.4;
  }
}
