.create-profile-success {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px 0;

  @media screen and (min-width: 480px) {
    padding: 60px 0;
    gap: 36px;

    .steps {
      justify-content: flex-start;
    }

    .create-profile-success_page {
      > svg {
        max-width: 400px;
        align-self: center;
      }
    }

    .create-profile-success_header {
      gap: 18px;
    }
  }

  @media screen and (min-width: 1200px) {
    gap: 76px;

    .create-profile-success_page {
      flex-direction: row-reverse;
      align-items: center;

      > div,
      > svg {
        flex: 1;
      }

      > svg {
        padding: 90px;
        max-width: 600px;
      }
    }

    .create-profile-success_content {
      gap: 30px;
    }

    .create-profile-success_header {
      gap: 18px;
    }
  }
}

.create-profile-success_page {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.create-profile-success_header {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.create-profile-success_content {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.create-profile-success_cta {
  display: flex;
  flex-direction: column;
  gap: 18px;
}
