.subscription-plan {
  padding: 30px;
  display: flex;
  flex-direction: column;
  border-radius: 30px;
  border: 1px solid $primary-100;
  height: 100%;
  background: linear-gradient(
    135deg,
    rgba(47, 47, 47, 0) 0%,
    rgba(66, 66, 66, 0.4) 100%
  );

  &.subscription-plan--premium {
    border-radius: 30px;
    border: 1px solid #59d9c6;
    background: linear-gradient(
      135deg,
      rgba(89, 217, 198, 0) 0%,
      rgba(89, 140, 217, 0.4) 100%
    );
  }

  @media screen and (min-width: 900px) {
    height: auto;
  }
}

.subscription-plan_header {
  display: flex;
  align-items: center;
}

.subscription-pan_title-wrap {
  display: flex;
  align-items: center;
}

.subscription-plan_subscribed {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;

  svg {
    width: 18px;
    height: 18px;
  }
}

.subscription-plan_type {
  font-family: "PlayfairDisplay";
  color: $green-500;
  font-weight: 400;

  &.subscription-plan_type--plan {
    color: $white;

    > span {
      color: $green-500;
      font-family: "PlayfairDisplay";
    }
  }
}

.subscription-plan_description {
  margin: 24px 0;
}

.subscription-plan_plan {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 42px;
  margin-bottom: auto;
}

.subscription-plan-item {
  display: flex;
  align-items: center;
  gap: 6px;

  > .subscription-plan-item_indicator {
    width: 18px;
    height: 18px;
    background-color: $primary-100;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      width: 12px;
      height: 12px;
    }
  }

  &.subscription-plan-item--checked {
    > .subscription-plan-item_indicator {
      background-color: $secondary-500;
    }
  }
}

.subscription-plan_discount {
  border-radius: 6px;
  background: linear-gradient(135deg, #c6a924 0%, #f2eb91 100%);
  display: flex;
  padding: 3px 6px;
  align-items: center;
  gap: 6px;
  margin: 0 12px;

  > p {
    color: $primary-500;
  }
}

.subscription-plan_duration {
  color: $green-500;
}

.subscription-plan_subscribe {
  color: $white;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  margin-top: 24px;
}

.subscription-plan_subscription {
  > p {
    color: $green-500;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
  }
}

.subscription-plan-item_owned {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
