.paragraph {
  &.paragraph--p1 {
    font-family: "PlayfairDisplay";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  &.paragraph--p2 {
    font-family: "Montserrat";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
  }

  &.paragraph--p3 {
    font-family: "Montserrat";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
  }

  &.paragraph--title {
    font-family: "Montserrat";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }

  &.paragraph--subtitle {
    font-family: "Montserrat";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px;
  }

  @media screen and (min-width: 480px) {
    &.paragraph--p1 {
      font-size: 22px;
      line-height: 30px;
    }

    &.paragraph--p2 {
      font-size: 14px;
      line-height: 18px;
    }

    &.paragraph--p3 {
      font-size: 12px;
      line-height: 16px;
    }

    &.paragraph--title {
      font-size: 18px;
      line-height: 24px;
    }

    &.paragraph--subtitle {
      font-size: 14px;
      line-height: 18px;
    }
  }

  @media screen and (min-width: 1040px) {
    &.paragraph--p1 {
      font-size: 24px;
      line-height: 36px;
    }

    &.paragraph--p2 {
      font-size: 16px;
      line-height: 20px;
    }

    &.paragraph--p3 {
      font-size: 14px;
      line-height: 18px;
    }

    &.paragraph--title {
      font-size: 20px;
      line-height: 24px;
    }

    &.paragraph--subtitle {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
