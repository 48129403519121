.slider {
  display: flex;
  flex-direction: column;
}

.slider__top {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.slider__header {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  align-items: center;
}

.slider__arrows {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  gap: 6px;

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    height: 30px;
    width: 30px;
    border: 1px solid $white;
    border-radius: 100%;

    > svg {
      width: 18px;
      height: 18px;
      color: $white;
    }
  }

  @media screen and (min-width: 480px) {
    > button {
      height: 42px;
      width: 42px;

      > svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.slider__more {
  align-self: flex-end;
  margin-bottom: 24px;
}

.slider__slide {
  cursor: pointer;
}
