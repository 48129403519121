$white: #fff;
$white-transparent: rgba(255, 255, 255, 0.7);
$red: #fb7070;
$red-700: #e74c4c;
$primary-500: #1d1d1d;
$primary-100: #424242;
$primary-300: #2f2f2f;
$secondary-500: #dbc958;
$secondary-100: #f2ec91;
$secondary-300: #e6d972;
$secondary-700: #d2bc43;
$secondary-900: #c6a924;
$green-500: #59d9c6;

$linear-gradiant: linear-gradient(
  0deg,
  rgba(0, 0, 0, 1) 0%,
  rgba(255, 255, 255, 1) 100%
);
$linear-gradiant-reverse: linear-gradient(
  0deg,
  rgba(255, 255, 255, 1) 0%,
  rgba(0, 0, 0, 1) 100%
);

$font-family: "Montserrat";
