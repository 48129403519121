.about-us {
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  gap: 60px;

  @media screen and (min-width: 800px) {
    padding: 120px 0;
    gap: 120px;

    .about-us_list {
      gap: 120px;

      > :nth-child(2) {
        flex-direction: row-reverse;
      }
    }

    .about-us_item {
      flex-direction: row;
      gap: 60px;
      align-items: center;

      svg {
        padding: 0;
        flex: 1;
      }

      > div {
        flex: 1;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .about-us_item {
      svg {
        padding: 0 60px;
      }
    }
  }
}

.about-us_header {
  display: flex;
  flex-direction: column;
  gap: 12px;

  > :first-child {
    color: $green-500;
  }
}

.about-us_list {
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.about-us_item {
  display: flex;
  flex-direction: column;
  gap: 12px;

  svg {
    padding: 0 30px;
  }

  > div > .paragraph--p1 {
    margin-bottom: 12px;
    color: $green-500;
  }

  > div > h2 {
    margin-bottom: 36px;
  }
}
