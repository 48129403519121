.educators {
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  gap: 60px;

  > div {
    > h1 {
      margin-bottom: 12px;
    }
  }

  @media screen and (max-width: 900px) {
    .educators_grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media screen and (max-width: 600px) {
    .educators_grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.educators_grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
}

.educator {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  cursor: pointer;

  > img {
    max-width: 180px;
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    object-position: center;
    border-radius: 100%;
  }

  > div {
    display: flex;
    flex-direction: column;
    text-align: center;

    > :first-child {
      color: $white;
    }
  }
}
