.footer {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px 24px;
  position: relative;
  background-color: $primary-300;
  padding-bottom: 100px;

  @media screen and (min-width: 480px) {
    padding: 48px 48px 30px;
    padding-bottom: 100px;
  }

  @media screen and (min-width: 1040px) {
    padding: 0;
  }
}

.footer-container {
  max-width: 1900px;
  width: 100%;
  margin: 0 auto;
  display: flex;

  @media screen and (min-width: 1040px) {
    flex-direction: row;
    gap: 48px;
    padding: 48px 96px 72px;

    > :first-child {
      max-width: 420px;
    }

    > .footer_lists {
      display: flex;
      flex-direction: row;

      flex-grow: 1;
      grid-gap: 12px;

      .footer_list {
        max-width: 240px;
        width: 100%;
      }
    }

    .footer_copyright {
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);
    }

    .footer_list {
      gap: 18px;

      > li {
        padding: 6px 12px;
      }
    }

    .footer_header {
      gap: 18px;
    }

    .footer_description {
      margin: 12px 0;
    }
  }
}

.footer_header {
  display: flex;
  flex-direction: column;
  gap: 12px;

  svg {
    width: 110px;
    height: 55px;
  }
}

.footer_list {
  display: flex;
  flex-direction: column;
  gap: 12px;

  > li {
    color: $white;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    padding: 6px;

    > span {
      cursor: pointer;
    }
  }

  > .footer_list-header {
    font-size: 14px;
  }
}

.footer_follow {
  color: $green-500;
}

.footer_social {
  display: flex;
  align-items: center;
  gap: 30px;

  svg {
    color: $white;
    width: 24px;
    height: 24px;
  }
}

.footer_list-item {
  cursor: pointer;
}
