$secondary-color: $secondary-500;
$red-color: $red;

.input {
  display: flex;
  flex-direction: column;

  &.input--floating {
    .input_label {
      font-size: 10px;
      line-height: 1.2;
      font-weight: 600;
      opacity: 0.4;
      top: 0;
    }
  }

  &.input--error {
    .input_error {
      visibility: visible;
    }

    .input_inner {
      border-bottom-color: $red-color;
    }

    .input-hide-show {
      svg {
        color: $red-color;
      }
    }
  }

  &.input--focused {
    .input_inner {
      border-bottom-color: $secondary-color;
    }

    .input-hide-show {
      svg {
        color: $secondary-color;
      }
    }
  }

  @media screen and (min-width: 480px) {
    .input_field {
      font-size: 14px;
      line-height: 18px;
    }

    .input_inner {
      padding: 15px 12px;
    }

    .input_label {
      font-size: 14px;
      line-height: 18px;
    }

    .input_error {
      font-size: 12px;
      line-height: 16px;
      padding: 8px 12px;
    }
  }
}

.input_inner {
  display: flex;
  flex-direction: column;
  padding: 16px 12px 6px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  position: relative;
}

.input_label {
  font-weight: 300;
  color: $white;
  position: absolute;
  margin-bottom: 0;
  opacity: 0.7;
}

.input_error {
  font-size: 10px;
  line-height: 1.2;
  color: $red-color;
  align-self: flex-end;
  padding: 6px 12px;
  visibility: hidden;
}

.input_wrap {
  display: flex;
  align-items: center;
}

.input_field {
  flex-grow: 1;
}

.input-hide-show {
  cursor: pointer;
  margin-left: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 18px;
    height: 18px;
    color: $primary-100;
  }
}

.input_suggestions-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: min-content;
  max-height: 200px;
  z-index: 1;
  overflow: scroll;
  background-color: $primary-300;
  border-radius: 0px 0px 12px 12px;
  padding: 12px 0;
  border: 1px solid $primary-100;
  border-top: 1px solid $secondary-500;

  > ul > li {
    padding: 12px;
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;

    &.input_suggestions-item--selected {
      > :first-child {
        background-color: $primary-100;
        display: flex;
        align-items: center;
        justify-content: center;

        > span {
          width: 6px;
          height: 6px;
          background-color: $secondary-500;
          border-radius: 100%;
        }
      }
    }

    > :first-child {
      width: 12px;
      height: 12px;
      border-radius: 100%;
      border: 2px solid $primary-100;
    }

    > :last-child {
      color: $white;
      font-family: "Montserrat";
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 18px;
      opacity: 0.7;
    }
  }
}
