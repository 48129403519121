.carousel {
  margin: 0 -30px;

  .splide__pagination {
    position: relative;
    bottom: unset;
    left: unset;
    margin-top: 24px;
  }

  .splide__pagination__page {
    background-color: $white;
    opacity: 0.4;

    &.is-active {
      background-color: $white;
      opacity: 1;
    }

    &,
    &.is-active {
      width: 6px;
      height: 6px;
      transform: scale(1);
    }
  }

  @media screen and (min-width: 480px) {
    margin: 0 -60px;
  }

  @media screen and (min-width: 1040px) {
    margin: 0 0;
  }
}
