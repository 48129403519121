.filter {
  position: relative;

  &.filter--filled {
    .filter_button {
      background-color: #2f2f2f;
      border-color: #2f2f2f;
    }
  }

  &.filter--open {
    .filter_button {
      background-color: $white;

      .filter_label {
        color: $primary-500;
      }

      svg {
        color: $primary-500;
        transform: scale(-1);
      }

      .filter_filters-active {
        color: $white;
        background-color: #1d1d1d;
      }
    }
  }

  @media screen and (min-width: 480px) {
    .filter_button {
      padding: 12px 18px;
    }

    .filter_dropdown {
      padding: 24px 18px;
      gap: 12px;
    }
  }
}

.filter_button {
  padding: 6px 12px;
  display: flex;
  align-items: center;
  gap: 6px;
  border-radius: 12px;
  border: 1px solid $primary-100;
  cursor: pointer;

  svg {
    color: $white;
    width: 18px;
    height: 18px;
  }
}

.filter_label {
  color: $white;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.filter_dropdown {
  margin-top: 6px;
  background-color: $primary-500;
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  padding: 18px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-radius: 12px;
  border: 1px solid $primary-100;
  z-index: 2;
  max-height: 250px;
  overflow: scroll;

  > li {
    width: max-content;
  }
}

.filter_filters-active {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: $white;
  min-width: 18px;
  color: #1d1d1d;
  font-family: "Montserrat";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
